<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row headers text-dark text-center py-3">
                <h4 class="m-0 mx-auto"> {{ $t("experience") }} </h4>
            </div>
            <div class="row">
                <div class="sections p-3">
                    <div class="card w-100 border-0">
                        <div class="card-title mb-0">
                            <div class="float-left pt-1">
                                <span class="d-block"> {{ $t("freelancing") }} </span>
                                <span class="d-block"> {{ $t("freelancing_place") }} </span>
                            </div>
                            <div class="float-right">
                                <small> {{ $t("freelancing_date") }} </small>
                                <vs-button class="m-0 mt-1 ml-auto" size="mini" transparent active color="dark" @click="active1=!active1">
                                    <i class='bx bx-link mr-1'></i> {{ $t("attachment") }}
                                </vs-button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="w-100">
                            <div class="float-left">
                                <section class="text-dark ltr">
                                    <small> #Backend </small>
                                    <small> #Frontend </small>
                                </section>
                            </div>
                            <div class="float-right">

                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="card w-100 border-0 mt-4">
                        <div class="card-title mb-0">
                            <div class="float-left">
                                <div class="media mt-1">
                                    <img src="@/assets/images/neshan.png" class="place-logos" alt="neshan">
                                    <div class="media-body ml-2">
                                        <span class="d-block"> {{
                                                $t("senior_software_engineer")
                                            }} <small>({{ $t("full_time") }})</small> </span>
                                        <span class="d-block" v-html="$t('neshan')"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="float-right">
                                <small> {{ $t("neshan_date") }} </small>
                                <vs-button class="m-0 mt-1 ml-auto" size="mini" transparent active color="dark"
                                           @click="active4=!active4">
                                    <i class='bx bx-link mr-1'></i> {{ $t("attachment") }}
                                </vs-button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="w-100">
                            <div class="float-left">
                                <section class="text-muted ltr">
                                    <small> <a href="https://www.java.com/en" class="text-dark" target="_blank">
                                        #Java </a> </small>
                                    <small> <a href="https://spring.io/projects/spring-boot" class="text-dark"
                                               target="_blank"> #Spring_Boot </a> </small>
                                </section>
                            </div>
                            <div class="float-right">

                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="text-center">
                        <vs-button class="d-inline mt-3" transparent active color="dark" data-toggle="collapse" data-target="#collapseExperience" aria-expanded="false" aria-controls="collapseExperience">
                            {{ $t("more") }}
                        </vs-button>
                    </div>
                    <div class="collapse" id="collapseExperience">
                        <div class="card w-100 border-0 mt-3">
                            <div class="card-title mb-0">
                                <div class="float-left">
                                    <div class="media mt-1">
                                        <img src="@/assets/images/quera.jpg" class="place-logos" alt="quera">
                                        <div class="media-body ml-2">
                                            <span class="d-block"> {{ $t("software_engineer") }} <small>({{ $t("full_time") }})</small> </span>
                                            <span class="d-block"> {{ $t("quera") }} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-right">
                                    <div class="ltr">
                                        <small> {{ $t("quera_date") }} </small>
                                    </div>
                                    <vs-button class="m-0 mt-1 ml-auto" size="mini" transparent active color="dark"
                                               @click="active3=!active3">
                                        <i class='bx bx-link mr-1'></i> {{ $t("attachment") }}
                                    </vs-button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="w-100">
                                <div class="float-left">
                                    <section class="text-muted ltr">
                                        <small> <a href="https://www.python.org" class="text-dark" target="_blank">
                                            #Python </a> </small>
                                        <small> <a href="https://www.php.net" class="text-dark" target="_blank">
                                            #PHP </a> </small>
                                        <small> <a href="https://laravel.com" class="text-dark" target="_blank">
                                            #Laravel </a> </small>
                                    </section>
                                </div>
                                <div class="float-right">

                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="card w-100 border-0 mt-4">
                            <div class="card-title mb-0">
                                <div class="float-left">
                                    <div class="media mt-1">
                                        <img src="@/assets/images/mobintabaran.png" class="place-logos" alt="mobintabaran">
                                        <div class="media-body ml-2">
                                            <span class="d-block"> {{ $t("web_developer") }} <small>({{ $t("full_time") }})</small></span>
                                            <span class="d-block"> {{ $t("mobintabaran") }} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-right">
                                    <div class="ltr">
                                        <small> {{ $t("mobintabaran_date") }} </small>
                                    </div>
                                    <vs-button class="m-0 mt-1 ml-auto" size="mini" transparent active color="dark" @click="active2=!active2">
                                        <i class='bx bx-link mr-1'></i> {{ $t("attachment") }}
                                    </vs-button>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="w-100">
                                <div class="float-left">
                                    <section class="text-muted ltr">
                                        <small> <a href="https://www.java.com/en" class="text-dark" target="_blank">
                                            #Java </a> </small>
                                        <small> <a href="https://spring.io/projects/spring-boot" class="text-dark"
                                                   target="_blank"> #Spring_Boot </a> </small>
                                    </section>
                                </div>
                                <div class="float-right">

                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vs-dialog v-model="active1" :class="{'rtlModal' : this.language === 'fa' }">
            <ul class="list-group list-group-flush p-0 pt-2 text-center">
                <li class="list-group-item"> <a href="https://abolfazlmohajeri.ir" target="_blank"> abolfazlmohajeri.ir </a> </li>
                <li class="list-group-item"><a href="https://formy.top" target="_blank"> formy.top </a></li>
                <li class="list-group-item"><a href="https://traceit.ir" target="_blank"> traceit.ir </a></li>
                <li class="list-group-item"><a href="https://rabassa.ir" target="_blank"> rabassa.ir </a></li>
                <li class="list-group-item"><a href="https://placeyourad.ir" target="_blank"> placeyourad.ir </a></li>
                <li class="list-group-item"> <a href="https://server.abolfazlmohajeri.ir" target="_blank"> server.abolfazlmohajeri.ir </a> (Vps and Self Hosted Projects) </li>
                <li class="list-group-item"> Participated in a Lot of Freelancing Projects  </li>
            </ul>
        </vs-dialog>

        <vs-dialog v-model="active2" :class="{'rtlModal' : this.language === 'fa' }">
            <ul class="list-group list-group-flush p-0 pt-2 text-center">
                <li class="list-group-item"> <a href="http://mobintabaran.ir" target="_blank"> mobintabaran.ir </a> </li>
                <li class="list-group-item"> <a href="http://linetab.ir" target="_blank"> linetab.ir </a> </li>
            </ul>
        </vs-dialog>

        <vs-dialog v-model="active3" :class="{'rtlModal' : this.language === 'fa' }">
            <ul class="list-group list-group-flush p-0 pt-2 text-center">
                <li class="list-group-item"><a href="https://quera.org" target="_blank"> quera.org </a></li>
                <li class="list-group-item"> <a href="https://codecup.ir" target="_blank"> codecup.ir </a> </li>
            </ul>
        </vs-dialog>

        <vs-dialog v-model="active4" :class="{'rtlModal' : this.language === 'fa' }">
            <ul class="list-group list-group-flush p-0 pt-2 text-center">
                <li class="list-group-item"><a href="https://neshan.org" target="_blank"> neshan.org </a></li>
            </ul>
        </vs-dialog>
    </div>
</template>

<script>
    export default {
        name: "Experience",
        data() {
            return {
                language: this.$i18n.locale,
                active1: false,
                active2: false,
                active3: false,
                active4: false
            }
        },
        mounted() {

        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>

</style>
