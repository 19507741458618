<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row headers text-dark text-center py-3">
                <h4 class="m-0 mx-auto"> {{ $t("skill") }} </h4>
            </div>
            <div class="row">
                <div class="sections py-3 px-3 w-100 text-left">
                    <div id="chartdiv" class="en-inputs w-100 ltr"></div>
                    <div class="en-inputs">
                        <span class="text-justify"> <small> <b> Java </b> <span class="font-weight-light"> : Java & Spring Boot </span> </small> </span>|
                        <span class="text-justify"> <small> <b> PHP </b> <span class="font-weight-light"> : PHP & Laravel </span> </small> </span>|
                        <span class="text-justify"> <small> <b> Front </b> <span class="font-weight-light"> : Html & Css & Pure JS </span> </small> </span>
                    </div>
                    <div class="text-center">
                        <vs-button class="d-inline mt-3" transparent active color="dark" data-toggle="collapse"
                                   data-target="#collapseSkills" aria-expanded="false" aria-controls="collapseSkills">
                            {{ $t("more") }}
                        </vs-button>
                    </div>
                    <div class="collapse" id="collapseSkills">
                        <span class="pt-2 d-table"> {{ $t("try") }}: </span>
                        <div id="tech-chart" class="en-inputs w-100 ltr"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
        name: "Skill",
        data() {
            return {}
        },
        mounted() {
            am4core.useTheme(am4themes_animated);

            let chart = am4core.create("chartdiv", am4charts.XYChart);
            chart.logo.disabled = true;
            chart.hiddenState.properties.opacity = 0;
            chart.logo.height = -300;
            chart.paddingRight = 40;

            chart.data = [{
                "name": "Front",
                "steps": 60,
                "href": "/images/f.png"
            }, {
                "name": "PHP",
                "steps": 70,
                "href": "/images/p.png"
            }, {
                "name": "Python",
                "steps": 65,
                "href": "/images/p.png"
            }, {
                "name": "JAVA",
                "steps": 80,
                "href": "/images/j.png"
            }];

            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.minGridDistance = 10;
            categoryAxis.renderer.labels.template.dx = -40;
            categoryAxis.renderer.minWidth = 120;
            categoryAxis.renderer.tooltip.dx = -40;

            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.inside = false;
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.max = 100;
            valueAxis.cursorTooltipEnabled = false;
            valueAxis.renderer.baseGrid.strokeOpacity = 0;
            valueAxis.renderer.labels.template.dy = 0;

            let series = chart.series.push(new am4charts.ColumnSeries);
            series.dataFields.valueX = "steps";
            series.dataFields.categoryY = "name";
            series.tooltipText = "{valueX.value}";
            series.tooltip.pointerOrientation = "vertical";
            series.tooltip.dy = -10;
            series.columnsContainer.zIndex = 100;

            let columnTemplate = series.columns.template;
            columnTemplate.height = am4core.percent(20);
            columnTemplate.maxHeight = 20;
            columnTemplate.column.cornerRadius(60, 10, 60, 10);
            columnTemplate.strokeOpacity = 0;

            series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueX", min: am4core.color("#9e9e9e"), max: am4core.color("#000000") });
            series.mainContainer.mask = undefined;

            let cursor = new am4charts.XYCursor();
            chart.cursor = cursor;
            cursor.lineX.disabled = true;
            cursor.lineY.disabled = true;
            cursor.behavior = "none";

            let bullet = columnTemplate.createChild(am4charts.CircleBullet);
            bullet.circle.radius = 15;
            bullet.valign = "middle";
            bullet.align = "left";
            bullet.isMeasured = true;
            bullet.interactionsEnabled = false;
            bullet.horizontalCenter = "right";
            bullet.interactionsEnabled = false;

            let image = bullet.createChild(am4core.Image);
            image.width = 25;
            image.horizontalCenter = "middle";
            image.verticalCenter = "middle";
            image.propertyFields.href = "href";

            image.adapter.add("mask", function (mask, target) {
                let circleBullet = target.parent;
                return circleBullet.circle;
            });

            chart = am4core.create("tech-chart", am4plugins_forceDirected.ForceDirectedTree);
            chart.logo.disabled = true;

            let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
            networkSeries.dataFields.linkWith = "linkWith";
            networkSeries.dataFields.name = "name";
            networkSeries.dataFields.id = "name";
            networkSeries.dataFields.value = "value";
            networkSeries.dataFields.children = "children";

            networkSeries.nodes.template.label.text = "{name}";
            networkSeries.fontSize = 12;
            networkSeries.linkWithStrength = 0;

            let nodeTemplate = networkSeries.nodes.template;
            nodeTemplate.tooltipText = "{name}";
            nodeTemplate.fillOpacity = 1;
            nodeTemplate.label.hideOversized = true;
            nodeTemplate.label.truncate = true;

            let linkTemplate = networkSeries.links.template;
            linkTemplate.strokeWidth = 2;
            let linkHoverState = linkTemplate.states.create("hover");
            linkHoverState.properties.strokeOpacity = 1;
            linkHoverState.properties.strokeWidth = 2;

            nodeTemplate.events.on("over", function (event) {
                let dataItem = event.target.dataItem;
                dataItem.childLinks.each(function (link) {
                    link.isHover = true;
                })
            });

            nodeTemplate.events.on("out", function (event) {
                let dataItem = event.target.dataItem;
                dataItem.childLinks.each(function (link) {
                    link.isHover = false;
                })
            });

            networkSeries.data = [
                {
                    "name": "C++",
                    "value": 100
                },
                {
                    "name": "CMS",
                    "value": 200,
                    "children": [
                        {
                            "name": "WordPress",
                            "value": 100
                        }
                    ]
                },
                {
                    "name": "VCS & Repo Hosting Service",
                    "value": 200,
                    "linkWith": [
                        "Technologies"
                    ],
                    "children": [
                        {
                            "name": "Git",
                            "value": 100
                        },
                        {
                            "name": "GitHub",
                            "value": 100
                        },
                        {
                            "name": "GitLab",
                            "value": 100
                        }
                    ]
                },
                {
                    "name": "AI & ML",
                    "value": 200,
                    "linkWith": [
                        "Technologies"
                    ],
                    "children": [
                        {
                            "name": "PyTorch",
                            "value": 100
                        },
                        {
                            "name": "NumPy",
                            "value": 100
                        },
                        {
                            "name": "Pandas",
                            "value": 100
                        },
                        {
                            "name": "Matplotlib",
                            "value": 100
                        }
                    ]
                },
                {
                    "name": "Front",
                    "value": 200,
                    "linkWith": [
                        "Technologies"
                    ],
                    "children": [
                        {
                            "name": "Vue",
                            "value": 100
                        },
                        {
                            "name": "jQuery",
                            "value": 100
                        },
                        {
                            "name": "Bootstrap",
                            "value": 100
                        },
                        {
                            "name": "Thymeleaf",
                            "value": 100
                        }
                    ]
                },
                {
                    "name": "Database",
                    "value": 200,
                    "linkWith": [
                        "Technologies"
                    ],
                    "children": [
                        {
                            "name": "MySQL",
                            "value": 100
                        },
                        {
                            "name": "PostgreSQL",
                            "value": 100
                        },
                        {
                            "name": "Redis",
                            "value": 100
                        },
                        {
                            "name": "Lucene",
                            "value": 100
                        }
                    ]
                }
            ];
        },
        methods : {

        }
    }
</script>

<style>

</style>

<style scoped>
    #chartdiv {
        height: 300px;
    }

    #tech-chart {
        height: 350px;
    }
</style>
